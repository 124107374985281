import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import useJwt from '@/auth/jwt/useJwt'
import { subject } from '@casl/ability'
import axiosIns from '@/libs/axios'

// Compoent
Vue.component(FeatherIcon.name, FeatherIcon)

// Const
const customerCategoryObj = {
  personal: {
    value: 'personal',
    label: 'Cá nhân',
  },
  company: {
    value: 'company',
    label: 'Doanh nghiệp',
  },
}
const contractApproving = {
  new: {
    value: 'new',
    label: 'Chờ duyệt',
  },
  agree: {
    value: 'agree',
    label: 'Đồng ý',
  },
  deny: {
    value: 'deny',
    label: 'Không đồng ý',
  },
}
const userJobTitleOptions = [
  {
    value: 'TDPC',
    label: 'Trưởng Đội Pháp Chế',
    zIndex: 10,
  },
  {
    value: 'PDPC',
    label: 'Phó Đội Pháp Chế',
    zIndex: 6,
  },
  {
    value: 'DPC',
    label: 'Chuyên Viên Pháp Chế',
    zIndex: 2,
  },
]
const templateTypeOptions = [
  { label: 'Hợp đồng/Phụ lục', value: 'form' },
  { label: 'Phiếu yêu cầu', value: 'ticket' },
]

const statusObj = {
  entry_new: {
    value: 'entry_new',
    label: 'Hồ sơ mới tiếp nhận',
    icon: 'FileTextIcon',
    color: '#28c76f',
    actionBeforeLabel: 'Tạo hồ sơ',
    next: ['verify_new'],
    type: 'hs',
    abilityNeeded: 'assign',
  },
  verify_new: {
    value: 'verify_new',
    label: 'Hồ sơ đang xác minh',
    icon: 'UsersIcon',
    color: '#ff993c',
    actionBeforeLabel: 'Chuyển đội - Xác minh',
    next: ['verify_approving'],
    type: 'hs',
    abilityNeeded: 'verification',
  },
  verify_approving: {
    value: 'verify_approving',
    label: 'Đang chờ phê duyệt',
    color: '#756bc9',
    icon: 'InfoIcon',
    actionBeforeLabel: 'Xác minh',
    next: ['verify_approving'],
    type: 'hs',
    abilityNeeded: 'verification',
  },
  contract_open: {
    value: 'contract_open',
    label: 'Hợp đồng mở',
    color: '#9dc213',
    icon: 'RotateCcwIcon',
    actionBeforeLabel: 'Hợp đồng mở',
    next: [],
    type: 'hd',
    abilityNeeded: 'browsing',
  },
  offer_to_sign: {
    value: 'offer_to_sign',
    label: 'Đề nghị ký HĐ',
    color: '#00cfe8',
    icon: 'CheckIcon',
    actionBeforeLabel: 'Duyệt',
    next: ['contract_signed', 'contract_denied'],
    type: 'hs',
    abilityNeeded: 'browsing',
  },
  verify_deny: {
    value: 'verify_deny',
    label: 'Trả hồ sơ',
    color: '#fdba85',
    icon: 'XIcon',
    actionBeforeLabel: 'Từ chối duyệt',
    next: ['verify_new'],
    type: 'hs',
    abilityNeeded: 'browsing',
  },
  contract_signed: {
    value: 'contract_signed',
    label: 'Hợp đồng đã ký',
    color: '#ea5455',
    icon: 'PenToolIcon',
    actionBeforeLabel: 'Ký hợp đồng',
    next: ['liquidity_processing', 'contract_open'],
    type: 'hd',
    abilityNeeded: 'liquidating',
  },
  contract_denied: {
    value: 'contract_denied',
    label: 'KH không đồng ý ký',
    color: '#4c42a6',
    icon: 'SlashIcon',
    actionBeforeLabel: 'KH không ký',
    next: ['liquidity_processing'],
    type: 'hs',
    abilityNeeded: 'liquidating',
  },
  liquidity_processing: {
    value: 'liquidity_processing',
    label: 'HĐ chờ thanh lý',
    color: '#b598be',
    icon: 'PauseIcon',
    actionBeforeLabel: 'Thanh lý',
    next: ['liquidity_completed'],
    type: 'hd',
    abilityNeeded: 'liquidating',
  },
  liquidity_completed: {
    value: 'liquidity_completed',
    label: 'Thanh lý HĐ',
    color: '#297c20',
    icon: 'CoffeeIcon',
    actionBeforeLabel: 'Đã Thanh lý',
    next: [],
    type: 'hd',
  },
}

const statusObjEx = {
  ...statusObj,
  contract_new: {
    value: 'contract_new',
    label: 'Đề nghị ký HĐ (cũ)',
    color: '#00cfe8',
    icon: 'CheckIcon',
    actionBeforeLabel: 'Duyệt',
    next: ['contract_signed', 'contract_denied'],
    type: 'hs',
    abilityNeeded: 'browsing',
  },
}

const genderObj = {
  male: {
    label: 'Anh',
    value: 'male',
  },
  female: {
    label: 'Chị',
    value: 'female',
  },
}

const optionsGender = {
  male: {
    value: 'male',
    label: 'Ông',
  },
  female: {
    value: 'female',
    label: 'Bà',
  },
}

const gender = {
  get: k => genderObj[k],
  getName: k => genderObj[k]?.label || k,
  genderObj,
  list: Object.values(genderObj),
}

const optionsGenderOjb = {
  get: k => optionsGender[k],
  getName: k => optionsGender[k]?.label || k,
  optionsGender,
  list: Object.values(optionsGender),
}

const status = {
  get: k => statusObj[k],
  getName: k => statusObj[k]?.label || k,
  getNameEx: k => statusObjEx[k]?.label || k,
  getColor: k => statusObj[k]?.color || k,
  statusObj,
  list: Object.values(statusObj),
}
const customerCategory = {
  get: k => customerCategoryObj[k],
  getName: k => customerCategoryObj[k]?.label || k,
  customerCategoryObj,
  list: Object.values(customerCategoryObj),
}

const years = []
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const monthsWithYear = []

// nếu chỉnh sửa thì cần cập nhật ở 1 vi trí khác
const howKnowMe = [
  {
    value: 'google',
    text: 'Google',
  },
  {
    value: 'khach-cu',
    text: 'Khách hàng cũ',
  },
  {
    value: 'gioi-thieu',
    text: 'Giới thiệu',
  },
  {
    value: 'banner',
    text: 'Banner',
  },
  {
    value: 'khac',
    text: 'Khác',
  },
]
let currentMonth = new Date().getMonth() + 1
let currentYear = new Date().getFullYear()

for (let i = 0; i < 12; i += 1) {
  monthsWithYear.unshift({ name: `${currentMonth > 9 ? currentMonth : `0${currentMonth}`}/${currentYear}`, value: `${currentYear}/${currentMonth}` })
  currentMonth -= 1
  if (currentMonth === 0) {
    currentMonth = 12
    currentYear -= 1
  }
}
for (let i = 2010; i <= new Date().getFullYear(); i += 1) {
  years.unshift(i)
}

const KEY_TO_TEXT = {
  customer: 'Khách hàng',
  rep_card_date: 'Ngày cấp CCCD',
  category: 'Nhóm',
  debtor: 'Khách nợ',
  reply_at: 'Ngày trả lời',
  amount: 'Thu được',
  amount_for_team: 'Chi đội',
  amount_for_team_percent: '% Chi đội',
  syndicate_amount: 'Công đoàn',
  debt_type: 'Loại nợ',
  origin: 'Gốc',
  pay_out: 'Đã chi',
  yes: 'Có',
  no: 'Không',
  note: 'Ghi chú',
  plan_id: 'Ghi chú',
  interest: 'Lãi',
  view: 'Xem hồ sơ',
  team_fund: 'Quỹ đội',
  keep_amount: 'Giữ lại 3%',
  driver_amount: 'Lái xe',
  return_driver_amount: 'Trả lại lái xe',
  original_amount: 'Số tiền nợ gốc',
  interest_amount: 'Số tiền nợ lãi',
  fee_to_buy_original: 'Mức giá mua nợ gốc',
  fee_to_buy_interest: 'Mức giá mua nợ lãi',
  debt_description: 'Ghi chú',
  debt_reason: 'Lý do phát sinh nợ',
  debt_deny_reason: 'Lý do không trả',
  debt_duration: 'Thời gian phát sinh',
  debt_from_year: 'Năm phát sinh nợ',
  debt_from_month: 'Tháng phát sinh nợ',
  is_has_plan: 'Kế hoạch',
  status: 'Trạng thái',
  info_status: 'Tình trạng khách nợ',
  info_working_area: 'Ngành nghề hoạt động',
  info_debt_relationship: 'Mối quan hệ giữa KH-KN',
  info_more: 'Thông tin khác',
  info_document_attachment: 'Tài liệu Khách Hàng cung cấp',
  info_has_contacted_with_gov: 'Khách hàng đã nhờ đơn vị/cơ quan',
  ratio_of_pay_consultant: 'Đợt 3: Sau khi thu nợ đạt % giá trị nợ',
  ratio_of_refund: 'Tỉ lệ hoàn trả',
  amount_of_refund: 'Giá trị hoàn trả',
  amount_of_break_contract: 'Giá trị đền bù hợp đồng',
  fee_to_break_contract: 'Phí đền bù hợp đồng',
  document_files: 'Tài liệu đính kèm',
  contract_code: 'Mã HĐ',
  document_code: 'Mã HS',
  department_id: '#ID của đội',
  department_name: 'Tên đội',
  appendix_code: 'Mã số PLHĐ',
  department: 'Đội',
  out_date: 'Ngày cũ',
  real_sign_at: 'Ngày ký thực tế',
  approves: 'Điều tra & Phê duyệt',
  authenticators: 'Báo cáo xác minh',
  verify_duration: 'Dự kiến thời gian thu hồi',
  verify_suggestion_status: 'Đề xuất trạng thái tiếp theo',
  liquidity_result: 'Kết quả TL',
  expired_at: 'Hết hạn HĐ',
  liquidity_start_date: 'Ngày chờ TL',
  sign_at: 'Ngày ký HĐ',
  verify_estimate_time: 'Thời gian xác minh dự kiến',
  liquidity_finish_date: 'Ngày thanh lý',
  verify_info: 'Nội dung điều tra xác minh',
  is_calculate_team_fund: 'Nội thành HCM',
  creator_id: 'Mã PKH',
  customer_id: 'Mã khách hàng',
  is_send_sms: 'Gửi SMS',
  liquidity_code: 'Mã số PLHĐ',
  fee_for_consultant: 'TỔNG PHÍ TƯ VẤN',
  fee_for_consultant_input: 'Đợt 1: Sau khi nhận hồ sơ',
  fee_for_consultant_1: 'Đợt 1: Sau khi nhận hồ sơ',
  fee_for_consultant_contract: 'Đợt 2: Sau khi ký hợp đồng',
  fee_for_consultant_2: 'Đợt 2: Sau khi ký hợp đồng',
  fee_for_consultant_service: 'Đợt 3: Sau khi thu nợ đạt',
  fee_for_consultant_3: 'Đợt 3: Sau khi thu nợ đạt',
  total_debt_collection_amount: 'Tổng số tiền thu nợ',
  verify_status: 'Trạng thái xác minh',
  duration: 'Khoản thời gian',
  deptor_id: 'Mã khách nợ',
  is_hand_over: 'Đã giao đội',
  false: 'Không',
  true: 'Có',
  hand_over_at: 'Ngày giao đội',
  is_deleted: 'Đã xóa',
  amount_to_buy_interest: 'Giá trị mua nợ lãi',
  amount_to_buy_original: 'Giá trị mua nợ gốc',
  info_how_to_know_me_type: 'Biết công ty CP ĐT mua bán nợ Song Long qua đâu',
  info_how_to_know_me: 'Biết công ty CP ĐT mua bán nợ Song Long qua đâu',
  rep_job_title: 'Chức vụ',
  name: 'Tên',
  fullname: 'Họ và tên',
  full_name: 'Họ và tên',
  created_at: 'Ngày tạo',
  id: '#',
  code: 'Mã',
  authenticator_ids: 'Nhân viên thực hiện xác minh',
  rep_card_id: 'CCCD',
  updated_at: 'Ngày cập nhật',
  content: 'Nội dung',
  type: 'Loại',
  debt_collection: 'Thu nợ',
  agree: 'Đồng ý',
  deny: 'Trả hồ sơ',
  contract_id: 'Mã HĐ',
  user_id: 'Mã NV',
  phone: 'SĐT',
  address: 'Địa chỉ',
  street: 'Đường',
  ward: 'Phường/Xã',
  district: 'Quận/Huyện',
  province: 'Tỉnh',
  personal: 'Cá nhân',
  company: 'Công ty',
  old_department: 'Đội cũ',
  old_pkh: 'PKH cũ',
  entry_new: 'Hồ sơ mới tiếp nhận',
  verify_new: 'Hồ sơ đang xác minh',
  verify_approving: 'Đang chờ phê duyệt',
  contract_open: 'Hợp đồng mở',
  offer_to_sign: 'Đề nghị ký HĐ',
  verify_deny: 'Trả hồ sơ',
  contract_signed: 'Hợp đồng đã ký',
  contract_denied: 'KH không đồng ý ký',
  liquidity_processing: 'HĐ chờ thanh lý',
  liquidity_completed: 'Thanh lý HĐ',
  final_amount_for_team: '95% chi đội',
  collect_at: 'Ngày thu',
  consulting: 'Phí tư vấn',
}

Vue.prototype.$optionsGenderOjb = optionsGenderOjb
Vue.prototype.$yearOptions = years
Vue.prototype.$monthOptions = months
Vue.prototype.$monthWithYearOptions = monthsWithYear
Vue.prototype.$userData = getUserData()
Vue.prototype.$status = status
Vue.prototype.$gender = gender
Vue.prototype.$customerCategory = customerCategory
Vue.prototype.$contractApproving = contractApproving
Vue.prototype.$userJobTitleOptions = userJobTitleOptions
Vue.prototype.$templateTypeOptions = templateTypeOptions
Vue.prototype.$howKnowMe = howKnowMe

Vue.mixin({
  data() {
    return {
      formatOptions: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
          numericOnly: true,
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
      NUMBER_LOCALE_FORMAT: undefined, // vi-VN = 1.234.56 || en-IN = 1,234.789
      $status: status,
      $customerCategory: customerCategory,
      NO_UPDATE_TEXT: '(Chưa cập nhật)',
      KEY_TO_TEXT,
    }
  },
  methods: {
    getMonthByDate(datetime = '') {
      try {
        const date = new Date(datetime)
        return date.getMonth() + 1
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getYearByDate(datetime = '') {
      try {
        const date = new Date(datetime)
        return date.getFullYear()
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getTemplateShortName(name = '') {
      const arr = name.split('.')
      arr.pop()
      const str = arr.join('.')
      return `...${str.length < 25 ? str : str.substr(str.length - 25)}`
    },
    downloadUrl(url, label) {
      // url = 'https://source.unsplash.com/user/c_v_r/100x100'
      axiosIns.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label || `download-${new Date().getTime()}`
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    openLinkInNewTab(url) {
      if (!url) return
      window.open(url, '_blank')
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('bookmarksInSession')

      // Reset ability
      // ability(initialAbility)

      // Redirect to login page
      localStorage.clear()
      this.$router.push({ name: 'auth-login' })
    },
    getISODate(d) {
      try {
        const r = new Date(d)
        return r.toISOString()
      } catch (e) {
        return d
      }
    },
    logMe(data) {
      console.log(data)
    },
    hideModal(ref) {
      setTimeout(() => { this.$bvModal.hide(ref) })
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    nextStatus(contract) {
      const c = contract || {}
      const s = statusObj[c.status] || {}
      const n = s.next
      const an = s.abilityNeeded

      if (!n || !ability().can(an, subject('Contract', c))) return []
      return n.map(key => ({
        ...statusObj[key],
        label: statusObj[key].actionBeforeLabel,
      }))
    },
    $toastr(msg, variant = 'success', option = {}) {
      const eMsg = msg.response?.data?.message || msg.response?.data?.error?.message || msg
      this.$bvToast.toast(eMsg || msg?.message || 'Thông báo', {
        title: this.getTitleNotiByVariant(variant),
        variant,
        solid: true,
        ...option,
      })
    },
    formatDate(date, withTime = false) {
      if (!date) return ''
      try {
        const _ = new Date(date)
        let D = _.getDate()
        D = D < 10 ? `0${D}` : D
        let M = _.getMonth() + 1
        M = M < 10 ? `0${M}` : M
        let Y = _.getFullYear()
        Y = Y < 10 ? `0${Y}` : Y
        let h = _.getHours()
        h = h < 10 ? `0${h}` : h
        let m = _.getMinutes()
        m = m < 10 ? `0${m}` : m
        return `${D}/${M}/${Y}${withTime ? ` ${h}:${m}` : ''}`
      } catch (e) {
        return date
      }
    },
    formatDateShort(date, withTime = false) {
      if (!date) return ''
      try {
        const _ = new Date(date)
        let D = _.getDate()
        D = D < 10 ? `0${D}` : D
        let M = _.getMonth() + 1
        M = M < 10 ? `0${M}` : M
        let Y = _.getFullYear().toString().slice(-2)
        Y = Y < 10 ? `0${Y}` : Y
        let h = _.getHours()
        h = h < 10 ? `0${h}` : h
        let m = _.getMinutes()
        m = m < 10 ? `0${m}` : m
        return `${D}/${M}/${Y}${withTime ? ` ${h}:${m}` : ''}`
      } catch (e) {
        return date
      }
    },
    getTime(date) {
      const time = this.formatDate(date, true)
      if (!time) return ''
      const a = time.split(' ')[1].split(':')
      return `${a[0]}h ${a[1]}p`
    },
    $showNoti(title = 'Thông báo', type = 'success', option = {}) {
      if (!title) return
      const eMsg = (title.response && title.response.data && title.response.data.title && title.response.data.title.message) || title.message
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: eMsg || title,
          variant: type,
          icon: '',
          ...option,
        },
      })
    },
    $goback(route) {
      return route ? this.$router.push(route) : this.$router.go(-1)
    },
    $formatPercentMinMax(value, min = 0, max = 100) {
      let res = value
      if (res < min) res = min
      if (res > max) res = max
      return res
    },
    $action(promiseAction, showNoti = false) {
      return promiseAction
        .then(res => {
          if (showNoti) {
            this.$showNoti(typeof res === 'string' ? res : (res && res.message) || 'Thành công')
          }
          return res && res.data
        })
        .catch(err => {
          let eMsg = err?.response?.data?.message
          eMsg = eMsg || err?.response?.data?.error?.message || err.message || err
          this.$toastr(eMsg, 'danger')
          throw eMsg
        })
    },
    getTitleNotiByVariant(variant) {
      switch (variant) {
        case 'success':
        case 'primary':
          return 'Thành công'
        case 'warning': return 'Cảnh báo'
        case 'danger':
        case 'error':
          return 'Lỗi xảy ra'
        default: return 'Thông báo'
      }
    },
    $getItem(key) {
      try {
        let jsonData = localStorage.getItem(key)
        jsonData = jsonData === 'undefined' ? '""' : jsonData
        return JSON.parse(jsonData)
      } catch (e) {
        return ''
      }
    },
    formatNumber(value = '', keepFraction = 0) {
      if (!value) return '0'
      let res = value.toString()
      try {
        if (!Number.isNaN(res)) {
          let fractionNumber = res.split(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.')[1] || ''
          res = res.split(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? ',' : '.')[0] || ''
          res = res.replaceAll(this.NUMBER_LOCALE_FORMAT === 'vi-VN' ? '.' : ',', '')

          res = parseFloat(res) || 0

          fractionNumber = fractionNumber.substr(0, keepFraction)
          if (fractionNumber) {
            res = `${res},${fractionNumber}`
          }
          res = res.toLocaleString(this.NUMBER_LOCALE_FORMAT)
        }
      } catch (e) {
        console.log(`Exception error formatInputNumber: ${value}`)
      }
      return res || 0
    },
    $formatVnd(val, keepFraction = 0) {
      let num = val || 0
      let postfix = 'đ'
      if (num > 1e9) {
        num = Math.floor(num / (10 ** (9 - keepFraction))) / (10 ** keepFraction)
        postfix = 'tỷ'
      } else if (num > 1e6) {
        num = Math.floor(num / (10 ** (6 - keepFraction))) / (10 ** keepFraction)
        postfix = 'tr'
      } else {
        num = (num / 1).toFixed(0)
          .replace('.', ',')
        num = num.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return `${num} ${postfix}`
    },
    $formatVndForChart(val) {
      let num = val || 0
      let postfix = 'đ'
      if (num > 1e9) {
        num = Math.floor(num / 1e9) / 1
        postfix = 'tỷ'
      } else if (num > 1e6) {
        num = Math.floor(num / 1e6) / 1
        postfix = 'tr'
      } else {
        num = (num / 1).toFixed(0)
          .replace('.', ',')
        num = num.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return `${num}${postfix}`
    },
    shortUsername(str) {
      const firstChars = str.split(' ').map(item => item[0].toUpperCase())
      firstChars.pop()
      const name = str.split(' ').pop()
      return `${firstChars.join('.') ? `${firstChars.join('.')}.` : ''}${name}`
    },
    createHandle(name) {
      let str = name || ''
      if (str) {
        str += ''
        str = str.trim()
        str = str.toLowerCase()
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
        str = str.replace(/đ/g, 'd')
        str = str.replace(/\,/g, '-')
        str = str.replace(/\./g, '-')
        str = str.replace(/\!/g, '-')
        str = str.replace(/\?/g, '-')
        str = str.replace(/\~/g, '-')
        str = str.replace(/\ /g, '-')
        str = str.replace(/\|/g, '-')
        str = str.replace(/\./g, '-')
        str = str.replace(/\"/g, '-')
        str = str.replace(/\'/g, '-')
        str = str.replace(/\-\-+/g, '-')
        str = str.replace(/\s+/g, '-')
        str = str.replace(/[^\w\-]+/g, '')
        str = str.replace(/\-\-+/g, '-')
        str = str.replace(/^-+/, '')
        str = str.replace(/-+$/, '')
        if (str.slice(-1) === '-') str = str.substring(0, str.length - 1)
      }
      return str
    },
    flattenObject(obj) {
      const object = Object.create(null)
      const path = []
      const isObject = value => Object(value) === value
      const dig = _obj => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(_obj)) {
          path.push(key)
          if (isObject(value)) dig(value)
          else object[path.join('.')] = value
          path.pop()
        }
      }
      dig(obj)
      return object
    },
    diffFlatten(newFlat, oldFlat) {
      const updated = { ...newFlat }
      const removed = { ...oldFlat }

      // eslint-disable-next-line no-restricted-syntax
      for (const key in oldFlat) {
        if (((oldFlat[key] && oldFlat[key].toString()) === (newFlat[key] && newFlat[key].toString()))
          || ((oldFlat[key] === 0 || oldFlat[key] === '0') && (newFlat[key] === 0 || newFlat[key] === '0'))
        ) {
          delete updated[key]
          delete removed[key]
        }
      }

      return [updated, removed]
    },
    isValidDate(str) {
      if (typeof str !== 'string') return false
      const noSpace = str.replace(/\s/g, '')
      if (noSpace.length < 3) {
        return false
      }
      return Date.parse(noSpace) > 0
    },
    isValidNumber(str) {
      if (typeof str !== 'string' && typeof str !== 'number') return false
      return parseFloat(str).toString() === str
    },
    unflatenObject(flattenObject) {
      const unFlatten = Object.create(null)
      // eslint-disable-next-line no-restricted-syntax
      for (const [stringKeys, value] of Object.entries(flattenObject)) {
        const chain = stringKeys.split('.')
        let object = unFlatten

        // eslint-disable-next-line no-restricted-syntax
        for (const [i, key] of chain.slice(0, -1).entries()) {
          if (!object[key]) {
            const needArray = Number.isInteger(Number(chain[+i + 1]))
            object[key] = needArray ? [] : Object.create(null)
          }
          object = object[key]
        }
        const lastkey = chain.pop()
        object[lastkey] = value
      }
      return unFlatten
    },
  },
})
