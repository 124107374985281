export default [
  {
    path: '/overview',
    name: 'overview',
    component: () => import('@/views/overview/Overview.vue'),
    meta: {
      action: 'read',
      resource: 'Overview',
      breadcrumb: [
        {
          text: 'Tổng quan',
          active: true,
        },
      ],
    },
  },
]
